import React from "react"
import { graphql } from "gatsby"
import tw from "twin.macro"

// Components
import Layout from "@components/layout"
import Seo from "@components/seo"
import StaticHero from "@components/staticHero"
import Callout from "@components/callout"
import Container from "@components/container"
import Section from "@components/section"
import PatientVoice from "@components/PatientVoice"
import KeyPoints from "@components/keyPoints"
import PrimaryCta from "@components/PrimaryCta"
import InlineVideoSection from "@components/inlineVideoSection"
import InlineVideo from "@components/inlineVideo"
import Anchor from "@components/Anchor"
import DownloadItem from "@components/DownloadItem"
import LearnMore from "@components/learnMore"
import { Heading2, circleList, circleListItem } from "@styles/ui"

const points = [
  {
    image: "/images/icons/round/excessive-daytime-sleepiness-icon.svg",
    imageAlt: "Excessive daytime sleepiness icon",
    text: (
      <div tw="font-extrabold text-sm leading-[1.4] xl:text-xl">
        Excessive daytime sleepiness, the most common symptom&nbsp;of Idiopathic
        Hypersomnia.
      </div>
    ),
  },
  {
    image: "/images/icons/round/clipboard-icon.svg",
    imageAlt: "Clipboard icon",
    text: (
      <div tw="text-sm xl:text-xl">
        <div tw="font-extrabold text-center leading-[1.4] md:text-left">
          Severity of Idiopathic Hypersomnia symptoms, including:
        </div>
        <ul tw="list-disc text-left pl-5 mt-4 xl:mt-5">
          <li>Sleep inertia (severe grogginess or confusion when waking up)</li>
          <li>Long sleep time</li>
          <li>Cognitive impairment</li>
        </ul>
      </div>
    ),
  },
]

const callouts = [
  {
    heading: "Live & on-demand webinars",
    cta: {
      text: "Go to webinars",
      url: "https://www.jazzwebinars.com/xywav-idiopathic-hypersomnia?tab=ondemand",
      external: true,
    },
  },
]

const downloads = [
  {
    title: "XYWAV Idiopathic Hypersomnia Brochure",
    description:
      "Get ready to talk to your doctor about your Idiopathic Hypersomnia symptoms and questions about XYWAV.",
    pdf: "/pdf/XYWAV_IH_Brochure.pdf",
  },
  {
    title: "Idiopathic Hypersomnia Severity Scale (IHSS)",
    description:
      "Measure the severity of your Idiopathic Hypersomnia symptoms and be sure to share your results with your doctor.",
    pdf: "/pdf/IHSS_Downloadable_PDF.pdf",
  },
  {
    title: "Epworth Sleepiness Scale (ESS)",
    description:
      "Find out how your daytime sleepiness measures up and be sure to share your results with your doctor.",
    pdf: "/pdf/ESS_Downloadable_PDF.pdf",
  },
]

const learnMoreCtas = [
  {
    text: "Get answers to Frequently Asked Questions (FAQ) about XYWAV",
    url: "/idiopathic-hypersomnia/faq/",
  },
  {
    text: "Find out about the possible side effects of XYWAV",
    url: "/idiopathic-hypersomnia/side-effects/",
  },
]

const WhatIsXywavPage = ({ location, data }) => {
  return (
    <Layout location={location}>
      <Seo
        title="FDA-Approved Idiopathic Hypersomnia Treatment | XYWAV® for IH"
        description="Consider XYWAV® (calcium, magnesium, potassium, and sodium oxybates oral solution) as a treatment for your Idiopathic Hypersomnia (IH). It's a prescription medication that provides individualized dosing options. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />
      <StaticHero
        eyebrow="What is XYWAV?"
        imageData={data.heroImage}
        alt="Lady playing cards with young boy"
        jumpLinks={[
          { text: "Why consider XYWAV?", link: "#consider-XYWAV" },
          { text: "How was XYWAV studied?", link: "#studied" },
        ]}
        gradient="purple"
      >
        XYWAV is the only <nobr>FDA-approved</nobr> treatment for adults with
        Idiopathic Hypersomnia (IH)
      </StaticHero>

      {/* Why XYWAV */}
      <Section id="consider-XYWAV">
        <Container>
          <h2 css={[Heading2, tw`mb-3 xl:(mb-5)`]}>Why consider XYWAV?</h2>
          <p tw="mb-5 font-extrabold text-base xl:(mb-7 text-3xl)">
            Here are some reasons why you may consider XYWAV to treat your
            Idiopathic Hypersomnia.
          </p>

          <ol css={circleList}>
            <li css={circleListItem}>
              <strong>First and only: </strong>XYWAV is the first treatment of
              its kind to be approved by the FDA for Idiopathic Hypersomnia.
            </li>
            <li css={circleListItem}>
              <strong>Taken at night: </strong>
              XYWAV is different&mdash;it&apos;s a prescription medicine taken
              at night to help treat multiple symptoms of Idiopathic
              Hypersomnia, such as excessive daytime sleepiness, sleep inertia
              (severe grogginess or confusion when waking up), long sleep time,
              and cognitive impairment.
            </li>
            <li css={circleListItem}>
              <strong>Individualized dosing: </strong>Your doctor will help find
              the best dose for you with twice- and once-nightly options.
            </li>
          </ol>
          <Anchor
            link="/idiopathic-hypersomnia/talking-to-your-doctor/"
            hasArrow
            addedStyles={tw`font-bold`}
          >
            Get tips for talking to your <br tw="md:hidden" />
            doctor about XYWAV
          </Anchor>
        </Container>
      </Section>

      {/* Quote */}
      <PatientVoice
        quote={
          <>
            When I was first diagnosed with Idiopathic Hypersomnia, my treatment
            plan focused on excessive daytime sleepiness, but I also needed help
            managing my other IH symptoms. That&apos;s when my doctor told me
            about twice-nightly XYWAV.&rdquo;
          </>
        }
        patient={"Casey"}
        diagnosis={"XYWAV IH Ambassador"}
        imageData={data}
        altText={"Patient Casey sharing how XYWAV® helped her IH symptoms"}
        cta={{
          text: "Learn about taking XYWAV",
          url: "/idiopathic-hypersomnia/dosing/",
        }}
        theme="ih"
      />

      {/* Keypoints */}
      <Section bgColour={tw`bg-lilac`}>
        <img
          src={"/images/xywav-logo-large-callout.svg"}
          alt=""
          tw="hidden lg:(block absolute opacity-60 w-[700px] left-[-270px] top-[-40px])"
        />
        <Container>
          <h2 css={[Heading2, tw`mb-10 text-center`]}>
            XYWAV was studied across several aspects of Idiopathic Hypersomnia:
          </h2>
          <KeyPoints points={points} />
        </Container>
      </Section>

      {/* Call out */}
      <Callout
        heading="Stay in the loop!"
        cta={{
          text: "SIGN UP NOW",
          url: "/idiopathic-hypersomnia/stay-connected/",
        }}
      >
        <>
          <p tw="xl:mb-8 sm:mb-5">
            We&apos;re here to support you with information on Idiopathic
            Hypersomnia, starting XYWAV, tips for talking with your doctor,
            JazzCares<sup>&reg;</sup>, and more. Join our email list today.
          </p>
        </>
      </Callout>

      {/* Video */}
      <InlineVideoSection
        video={
          <InlineVideo
            wistiaId="zoj62xybuo"
            thumbnail={data.thumbImage}
            tall
            alt="Patient Diana shares her XYWAV® story"
          />
        }
      >
        <p tw="mb-4 md:mb-6">
          Diana, a real patient and XYWAV IH Ambassador, shares her XYWAV story.{" "}
        </p>
        <Anchor
          link="/idiopathic-hypersomnia/resources/"
          addedStyles={tw`text-sm font-bold text-left md:text-xl`}
          hasArrow
        >
          Hear more from Diana and
          <br />
          other XYWAV IH Ambassadors
        </Anchor>
      </InlineVideoSection>

      {/* Study methodology */}
      <Section id="studied">
        <Container>
          <h2 css={[Heading2, tw`mb-3 xl:(text-5xl mb-5)`]}>
            How was XYWAV studied?
          </h2>

          <ul tw="list-disc [padding-inline-start: 20px] mt-2.5 mb-6 text-sm before:(gradient23 mt-7.5 mb-5 relative left-[-20px]) md:(before:mt-[5px]) xl:(text-xl mt-7 mb-[45px]) [>strong]:font-black">
            <li>
              <strong>
                The efficacy and safety of XYWAV was studied in 115 people aged
                19-75 with Idiopathic Hypersomnia.
              </strong>
            </li>
            <li tw="mt-3.5 xl:(mt-2)">
              <strong>
                All people in the clinical study started treatment with XYWAV.
                Some participants continued taking their stimulant or
                wake&#8209;promoting agent along with XYWAV.
              </strong>
            </li>
            <li tw="mt-3.5 xl:(mt-2)">
              XYWAV was adjusted to a stable dose (the dose that worked best for
              each person). Then they were randomly assigned to:
              <ul tw="list-disc [padding-inline-start: 20px] xl:(mt-1)">
                <li tw="mt-2">
                  <strong>Continue treatment</strong> with XYWAV.
                </li>
                <li tw="uppercase list-none relative right-5 my-2">or</li>
                <li>
                  <strong>Stop taking XYWAV</strong> for 2 weeks. These people
                  were switched to a placebo, a treatment that is inactive. This
                  was to see if there was a difference in their symptoms.
                </li>
              </ul>
            </li>
          </ul>
        </Container>

        {/* Chart */}
        <div tw="bg-lilac pb-7 -mb-7 lg:(mb-0 rounded-3xl max-w-5xl mx-auto pt-10 pb-12) xl:(max-w-6xl)">
          <div tw="px-4 mx-auto max-w-4xl lg:(px-11 max-w-[unset])">
            <picture tw="mt-2.5">
              <source
                media="(min-width: 1024px)"
                srcSet={"/images/Efficacy-safety-study-graph.svg"}
              />
              <img
                src={"/images/Efficacy-safety-study-graph-mobile.svg"}
                alt="Efficacy and safety study graph"
                tw="mx-auto lg:mx-[unset]"
              />
            </picture>
            <div tw="mb-4 lg:(mb-5 -mt-5)">
              <p tw="text-[12px] lg:(text-xs)">
                This study was double-blinded. That means neither the
                investigators who ran the study nor the people in the study knew
                who got XYWAV or who got placebo during the period of the study
                that measured how well XYWAV worked.
              </p>
              <p tw="text-[12px] mt-1 lg:(text-xs)">
                *Not everyone completed the 10- to 14-week portion of the study
              </p>
            </div>
            <div tw="text-center">
              <PrimaryCta
                url="/idiopathic-hypersomnia/xywav-efficacy/"
                width={tw`w-[280px] lg:w-[265px]`}
              >
                See study results
              </PrimaryCta>
            </div>
          </div>
        </div>
      </Section>

      {/* Callout */}
      <Section>
        <Container>
          <h2
            css={[
              Heading2,
              tw`mb-3 text-center md:text-left xl:(text-5xl mb-5)`,
            ]}
          >
            How does XYWAV work?
          </h2>
          <p>
            Although the exact way XYWAV works is unknown, it is thought to work
            during sleep to help with daytime symptoms.
          </p>
        </Container>
      </Section>

      {/* Webinar callout */}
      <Callout
        heading={callouts[0].heading}
        cta={callouts[0].cta}
        noPadding={true}
      >
        <p>
          Check out our webinar series presented by experts in Idiopathic
          Hypersomnia and treatment with XYWAV. Programs cover a variety of
          topics and even include a live Q&A!
        </p>
      </Callout>

      {/* Resources */}
      <Section bgColour={tw`bg-lilac`}>
        <Container>
          <h2 css={[Heading2, tw`mb-7 lg:(mb-12)`]}>Want to know more?</h2>
          <DownloadItem downloads={downloads} />
        </Container>
      </Section>

      {/* Learn more */}
      <LearnMore ctas={learnMoreCtas} ih colour="purple" />
    </Layout>
  )
}

export default WhatIsXywavPage

export const query = graphql`
  query {
    heroImage: file(
      relativePath: { eq: "what-is-xywav-ih/lady-playing-cards.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
        )
      }
    }
    patientVoice: file(
      relativePath: {
        eq: "what-is-xywav-ih/patient-casey-ih-ambassador-quote.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 705, placeholder: NONE)
      }
    }
    patientVoiceMobile: file(
      relativePath: {
        eq: "what-is-xywav-ih/patient-casey-ih-ambassador-quote-mobile.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 767, placeholder: NONE)
      }
    }
    thumbImage: file(
      relativePath: { eq: "what-is-xywav-ih/patient-diana-xywav-story.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
  }
`
